/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnswerRequest } from '../models/AnswerRequest';
import type { State } from '../models/State';
import type { SupportFlowSettings } from '../models/SupportFlowSettings';
import type { SupportResponse } from '../models/SupportResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InternalApiForRequestsRedirectedFromB2BOrOurTeliaService {

    /**
     * Create a new session running a specific flow.
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static start1(
        requestBody: SupportFlowSettings,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/my-business/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a new session running a specific flow.
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static start2(
        requestBody: SupportFlowSettings,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/our-telia/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the current state
     * @param requestBody
     * @returns State OK
     * @throws ApiError
     */
    public static getState(
        requestBody: AnswerRequest,
    ): CancelablePromise<State> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/my-business/getstate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the current state
     * @param requestBody
     * @returns State OK
     * @throws ApiError
     */
    public static getState1(
        requestBody: AnswerRequest,
    ): CancelablePromise<State> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/our-telia/getstate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the same result as the last call to start or answer
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static getLastResult(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/my-business/getlastresult',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the same result as the last call to start or answer
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static getLastResult1(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/our-telia/getlastresult',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Go back to the last state
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static goBack(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/our-telia/back',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Go back to the last state
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static goBack1(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/my-business/back',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Submit the user's answers to one or more questions.
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static answer(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/our-telia/answer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Submit the user's answers to one or more questions.
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static answer1(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/my-business/answer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}