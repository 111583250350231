/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnswerRequest } from '../models/AnswerRequest';
import type { State } from '../models/State';
import type { SupportFlowSettings } from '../models/SupportFlowSettings';
import type { SupportResponse } from '../models/SupportResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiForSupportCustomerTroubleshootingFlowService {

    /**
     * Create a new session running a specific flow.
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static start(
        requestBody: SupportFlowSettings,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the current state
     * @param requestBody
     * @returns State OK
     * @throws ApiError
     */
    public static getState2(
        requestBody: AnswerRequest,
    ): CancelablePromise<State> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/getstate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get the same result as the last call to start or answer
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static getLastResult2(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/getlastresult',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Go back to the last state
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static goBack2(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Submit the user's answers to one or more questions.
     * @param requestBody
     * @returns SupportResponse OK
     * @throws ApiError
     */
    public static answer2(
        requestBody: AnswerRequest,
    ): CancelablePromise<SupportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/answer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}